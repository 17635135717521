import React,{useState} from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';
import { Trans } from "react-i18next";
import i18n from "i18next";
import TextField from '@material-ui/core/TextField';

import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../_other/CustomDatePicker";



let sha1 = require('sha1');

interface MyProps {
    selectedCompany:any,
    onCompanyAdded():any,
    onCompanyEdited():any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedCompany:any,
}

class ModalAddNewCompany extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:[],

            selectedCompany: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);

    }

    componentDidMount(){
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleDateChange(newDate){
        let formFields = this.state.formFields;
        formFields['ValidUntil'] = newDate;
        this.setState({ formFields: formFields });
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});


        const { formFields,selectedCompany } = this.state;
        const Name = formFields.Name;
        const TotalEntries = formFields.TotalEntries;
        const UsedEntries = formFields.UsedEntries;
        const ValidUntil = formFields.ValidUntil;


        // stop here if form is invalid
        if (!(Name) || !(ValidUntil)) {
            toast.error(i18n.t("msg_missing_fields"));
            return;
        }


        let company = {Name: Name};

        if(TotalEntries){
            company['TotalEntries'] = TotalEntries;
        }

        if(UsedEntries){
            company['UsedEntries'] = UsedEntries;
        }

        if(ValidUntil){
            company['ValidUntil'] = ValidUntil;
        }

        if(selectedCompany){
            company['IdCompany'] = selectedCompany.IdCompany;
        }
        serverService.insertCompany(company)
            .then(
                result => {
                    if(result.status == 1){
                        if(selectedCompany){
                            toast.success(i18n.t("msg_company_edited"));
                            this.props.onCompanyEdited();
                        } else {
                            toast.success(i18n.t("msg_company_added"));
                            this.props.onCompanyAdded();
                        }
                    } else {
                        toast.error(i18n.t("msg_company_add_failed"));
                    }
                    this.setState({submitted: false});
                }, error => {
                    toast.warn(i18n.t("msg_company_add_failed_server"));
                }
            );

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedCompany !== prevProps.selectedCompany){

            let formFields = this.state.formFields;
            formFields.Name = "";
            formFields.TotalEntries = "";
            formFields.UsedEntries = "";
            formFields.ValidUntil = "";


            if(this.props.selectedCompany && typeof(this.props.selectedCompany.IdCompany) !== "undefined"){
                formFields.Name = this.props.selectedCompany.Name;
                formFields.TotalEntries = this.props.selectedCompany.TotalEntries;
                formFields.UsedEntries = this.props.selectedCompany.UsedEntries;
                formFields.ValidUntil = this.props.selectedCompany.ValidUntil;
            }

            this.setState({selectedCompany: this.props.selectedCompany, formFields:formFields, submitted: false});
        }
    }



    render() {

        const {submitted, formFields} = this.state;


        let titleStr = i18n.t("add_company");
        if(this.state.selectedCompany && typeof(this.state.selectedCompany.IdCompany) !== "undefined"){
            titleStr = i18n.t("edit_company");
        }

        let confirmButtonStr = i18n.t("add_company");
        if(this.state.selectedCompany && typeof(this.state.selectedCompany.IdCompany) !== "undefined"){
            confirmButtonStr = i18n.t("save");
        }

        console.log(this.state.formFields.ValidUntil);

        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <br/>

                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.name ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="Name"
                                value={formFields.Name}
                                label={i18n.t("name")}
                                placeholder={i18n.t("name")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="text"
                            />

                            {submitted && !formFields.Name &&
                            <div className="help-block">
                                <Trans>
                                    name_is_mandatory
                                </Trans>
                            </div>
                            }
                        </div>


                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.TotalEntries ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="TotalEntries"
                                value={formFields.TotalEntries}
                                label={i18n.t("total_entries")}
                                placeholder={i18n.t("total_entries")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="text"
                            />
                        </div>

                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.UsedEntries ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="UsedEntries"
                                value={formFields.UsedEntries}
                                label={i18n.t("used_entries")}
                                placeholder={i18n.t("used_entries")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="text"
                            />
                        </div>


                        <div className={'form-group mb-4'} style={{marginLeft:'45px'}}>
                            <Trans>valid_until</Trans>
                            <br/>
                            <CustomDatePicker startDate={formFields.ValidUntil} handleDateChange={this.handleDateChange} />
                        </div>

                        <br/>
                        <hr/>

                        <Button variant="primary" type="submit" style={{float:'right'}}>
                            {confirmButtonStr}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );

    }



}


export default ModalAddNewCompany;