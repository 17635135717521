import React from 'react';
import { toast } from "react-toastify";

import { Form, Button, ButtonToolbar } from "react-bootstrap";

import { serverService } from '../../_services/server.service';

import { Trans } from "react-i18next";
import i18n from "i18next";
import TextField from '@material-ui/core/TextField';

import PubSub from '../../_helpers/PubSub.js'
import ModalConfirmAction from "../_other/ModalConfirmAction";
import ModalAddNewCompany from "./ModalAddNewCompany";

const $ = require('jquery');
$.DataTable = require('datatables.net');

require('datatables.net-bs4');
require('datatables.net-select-bs4');
require('../datatables.selected.css');
require('datatables.net-buttons-bs4')();
require('datatables.net-buttons/js/buttons.html5.js')();


interface MyWindow extends Window {
    JSZip: any;
}
declare let window: MyWindow;

window.JSZip = require('jszip');

const moment = require('moment');


interface MyProps {
}

interface MyState {
    loading: boolean,
    items: any,
    searchQuery: string,
    modalAddNewShow: boolean,
    modalConfirmDeleteShow: boolean,
    selectedCompany: any,
}

class CompaniesTable extends React.Component<MyProps, MyState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            items: [],
            searchQuery: '',
            modalAddNewShow: false,
            modalConfirmDeleteShow: false,
            selectedCompany: {}
        };

        this.handleChange = this.handleChange.bind(this);

        this.modalAddCompanyOpen = this.modalAddCompanyOpen.bind(this);
        this.modalEditCompanyOpen = this.modalEditCompanyOpen.bind(this);
        this.modalAddClose = this.modalAddClose.bind(this);
        this.modalConfirmDeleteOpen = this.modalConfirmDeleteOpen.bind(this);
        this.modalConfirmDeleteClose = this.modalConfirmDeleteClose.bind(this);
        this.modalConfirmDeleteNo = this.modalConfirmDeleteNo.bind(this);

        this.onCompanyAdded = this.onCompanyAdded.bind(this);
        this.onCompanyEdited = this.onCompanyEdited.bind(this);

        this.modalConfirmDeleteYes = this.modalConfirmDeleteYes.bind(this);


    }
    componentDidMount() {

        let _this = this;

        let table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            // "<'row'<'col-md-6 my-auto'<'toolbar'>><'col-md-6 data-table-search'f>>" +
            dom: "<'data-table-wrapper'" +
                "<'row'<'col-md-6 my-auto'<'toolbar'>><'col-md-6 data-table-search'>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-6'><'col-6'p>>" +
                ">",
            data: this.state.items,
            ordering: true,
            paging: true,
            buttons: [
                { extend: 'excel', text: i18n.t("export_excel") }
            ],
            "order": [[0, "desc"]],
            columns: [
                {
                    title: "Nume",
                    width: 60,
                    data: "Name"
                },
                {
                    title: "Total intrari",
                    width: 60,
                    data: "TotalEntries"
                },
                {
                    title: "Intrari folosite",
                    width: 60,
                    data: "UsedEntries"
                },
                {
                    title: "Valabile pana la",
                    width: 60,
                    data: "ValidUntil"
                },
            ],
            language: {
                search: i18n.t("search"),
                emptyTable: i18n.t("table_no_records"),
                paginate: {
                    first: i18n.t("first"),
                    previous: i18n.t("previous"),
                    next: i18n.t("next"),
                    last: i18n.t("last")
                }
            },

            select: {
                style: 'single'
            },

            columnDefs: [
                {
                    render: function (data, type, row) {
                        if (type === 'display' || type === 'filter') {
                            if (data != null) {
                                if (data === "0000-00-00") {
                                    return "Nelimitat";
                                }
                                return moment(data).format('DD/MM/YYYY');
                            } else {
                                return "";
                            }
                        }
                        return data;
                    },
                    targets: 3,
                }
            ],
        });


        $("#deleteBtn").hide();
        $("#editBtn").hide();


        table.on( 'select', function ( e, dt, type, indexes ) {
            $("#deleteBtn").show();
            $("#editBtn").show();


            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();

            _this.setState({selectedCompany: data[0] });
        });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            $("#editBtn").hide();

            _this.setState({selectedCompany: null });
        });


        this.setState({
            loading: false,
            items: [],
        });

        this.doFetchAll();
    }

    componentWillUnmount() {
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }


    componentDidUpdate(oldProps, oldState) {
        if (this.state.items !== oldState.items) {
            reloadTableData(this.state.items);
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let searchQuery = this.state.searchQuery;
        searchQuery = value;
        this.setState({ searchQuery: searchQuery });
    }

    render() {
        let searchQuery = this.state.searchQuery;
        searchInTable(searchQuery);

        return (
            <div
                className="mx-auto"
                style={{
                    maxWidth: '832px',
                    width: '98%'
                }}
            >
                <div className="row">
                    <div className="col-md-8" style={{marginTop:'auto', marginBottom: 'auto'}}>
                        <ButtonToolbar>
                            <Button
                                onClick={this.modalAddCompanyOpen}
                            >
                                <Trans>
                                    add_company
                                </Trans>
                            </Button>

                            &nbsp;


                            <Button
                                id="editBtn"
                                onClick={this.modalEditCompanyOpen}
                            >
                                <Trans>
                                    edit_company
                                </Trans>
                            </Button>

                            &nbsp;
                            <Button
                                id="deleteBtn"
                                variant="danger"
                                onClick={this.modalConfirmDeleteOpen}
                            >
                                <Trans>
                                    delete_company
                                </Trans>
                            </Button>

                        </ButtonToolbar>
                    </div>

                    <div className="col-md-4" style={{marginTop:'auto', marginBottom: 'auto'}}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            name="searchQuery"
                            value={this.state.searchQuery}
                            onChange={this.handleChange}
                            className="form-field is-search"
                            label={i18n.t("search")}
                            placeholder={i18n.t("search")}
                        />
                    </div>

                    <div className="col-md-12" id="eventsTableContainer">
                        <table ref="main" className="table table-bordered" />
                    </div>


                    <ModalAddNewCompany
                        show={this.state.modalAddNewShow}
                        selectedCompany={this.state.selectedCompany}
                        onHide={this.modalAddClose}
                        onCompanyAdded={this.onCompanyAdded}
                        onCompanyEdited={this.onCompanyEdited}
                    />


                    <ModalConfirmAction
                        show={this.state.modalConfirmDeleteShow}
                        actionTitle={i18n.t("confirm_delete_title")}
                        actionBody={i18n.t("confirm_delete_body")}
                        noBtnText={i18n.t("confirm_delete_no")}
                        yesBtnText={i18n.t("confirm_delete_yes")}
                        onConfirmNo={this.modalConfirmDeleteNo}
                        onConfirmYes={this.modalConfirmDeleteYes}
                        onHide={this.modalConfirmDeleteClose}
                    />
                </div>
            </div>);
    }


    doFetchAll() {

        let userStr = localStorage.getItem('user');
        let IdUser = 0;
        if (userStr) {
            let userObj = JSON.parse(userStr);
            IdUser = userObj.IdUser;
        }

        this.setState({ loading: true });
        serverService.getCompanies()
            .then(
                itemsRes => {
                    if (itemsRes && itemsRes.data != undefined) {
                        this.setState({ items: itemsRes.data, loading: false })
                    } else {
                        this.setState({ loading: false })
                    }
                },
                error => {
                    toast.warn("Did not get results for company");
                    this.setState({ loading: false });
                }
            );
    }


    modalConfirmDeleteNo(){
        this.modalConfirmDeleteClose();
    }

    modalConfirmDeleteYes(){
        console.log(this.state.selectedCompany);
        serverService.deleteCompany(this.state.selectedCompany.IdCompany)
            .then(result => {
                if(result.status  == 1){
                    toast.success(i18n.t("msg_delete_successfully"));
                } else {
                    toast.warn("Did not delete");
                }

                this.setState({modalConfirmDeleteShow: false, loading: false });
                this.doFetchAll();
            }, err => {
                toast.warn("Did not delete");
                this.setState({ loading: false });
            });

        deselectRowInTable();
    }


    onCompanyAdded(){
        this.doFetchAll();
        this.modalAddClose();
    }

    onCompanyEdited(){
        this.doFetchAll();
        this.modalAddClose();
        deselectRowInTable();
    }

    modalAddCompanyOpen(){
        deselectRowInTable();
        this.setState({ modalAddNewShow: true});
    }


    modalEditCompanyOpen(){
        this.setState({ modalAddNewShow: true});
    }

    modalAddClose(){
        this.setState({ modalAddNewShow: false});
    }

    modalConfirmDeleteOpen(){
        this.setState({ modalConfirmDeleteShow: true });
    }

    modalConfirmDeleteClose(){
        this.setState({ modalConfirmDeleteShow: false});
    }
}

function deselectRowInTable(){
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    let data = table.rows( { selected: true }).deselect();
}


function searchInTable(searchString) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.search(searchString);
    table.draw();
}

function reloadTableData(items) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(items);
    table.draw();

    let toolbar = $("#toolbar");
    $("div.toolbar").html(toolbar);

    table.columns.adjust().draw();
}




export default CompaniesTable;
