import {authHeader, authHeaderFile, noAuthHeader} from '../_helpers/AuthHeader';

import {config} from '../_helpers/C'

export const serverService = {
    login,
    forgotPassword,
    resetPassword,
    logout,

    getEvents,
    getUsers,
    getUserById,
    insertUser,
    deleteUser,

    getCompanies,
    insertCompany,
    deleteCompany,
};

const REACT_APP_SERVER_API_URL = config.API_BASE_URL;

function login(username, password) {
    const requestOptions = {
        method: 'GET',
        headers: noAuthHeader(),
    };

    return fetch(REACT_APP_SERVER_API_URL + `/public/auth`+"?Username="+username+"&Password="+password, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function forgotPassword(Email, language) {
    const requestOptions = {
        method: 'GET',
        headers: noAuthHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/auth/forgot_password` + "?Email=" + Email + "&language=" + language, requestOptions)
        .then(handleResponse);
}

function resetPassword(IdUser, Password, h) {
    const formObj = { "IdUser":IdUser, "Password":Password, "h": h};

    const requestOptions = {
        method: 'PUT',
        headers: noAuthHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/auth/reset_password`, requestOptions)
        .then(handleResponse);
}


function getEvents() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/events`, requestOptions)
        .then(handleResponse);
}


function getUsers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/users`, requestOptions)
        .then(handleResponse);
}


function getUserById(IdUser) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/users/` + IdUser, requestOptions)
        .then(handleResponse);
}

function insertUser(user) {
    const formObj = {"data": user};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/users`, requestOptions)
        .then(handleResponse);
}

function deleteUser(IdUser) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/users/` + IdUser, requestOptions)
        .then(handleResponse);
}


function getCompanies() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/companies`, requestOptions)
        .then(handleResponse);
}


function insertCompany(user) {
    const formObj = {"data": user};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/companies`, requestOptions)
        .then(handleResponse);
}

function deleteCompany(IdCompany) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private-admin/companies/` + IdCompany, requestOptions)
        .then(handleResponse);
}


function logout() {
    localStorage.removeItem('user');
}


function handleResponse(response) {
    return response.text().then(text => {
        let data;
        try {
            data = text && JSON.parse(text);

            if (data.status === "0" && data.message.includes("Unauthorized")) {
                // auto logout if 401 response returned from api
                logout();
                window.location.href = "/login";
            }

            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout();
                    window.location.href = "/login";
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        } catch (error){
            return false
        }
    });
}