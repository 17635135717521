/**
 * Created by Alex on 17-Oct-19.
 */
import React from 'react';

import {Typeahead} from 'react-bootstrap-typeahead';

interface MyProps {
    selectedItem?:string,
    onItemSelected(itemText:string, isANewItem:boolean):any,
    items:any,
}

interface MyState {
    items:any,
    selectedItem:string,
}

class ItemsAutocomplete extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            selectedItem:'',
        };

        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.doesItemAlreadyExists = this.doesItemAlreadyExists.bind(this);
    }

    componentDidMount(){
        this.setState({items:this.props.items});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedItem !== prevState.selectedItem) {
            this.setState({selectedItem: this.props.selectedItem});
        }
    }

    render() {
        let selectedItem = this.state.selectedItem;
        if(selectedItem == null){
            selectedItem = "";
        }
        return (
            <Typeahead
                allowNew
                bsSize="small"
                selected={[{'Name':selectedItem}]}
                id="itemsAct"
                options={this.state.items}
                labelKey="Name"
                onInputChange={this.handleOnInputChange}
                onChange={this.handleOnChange}
                placeholder="Search..."
                newSelectionPrefix="You have to select an existing item: "
            />
        );
    }

    handleOnInputChange(itemstring:string) {
        this.props.onItemSelected(itemstring,this.doesItemAlreadyExists(itemstring));
    }

    handleOnChange(itemsObject:any) {
        if(itemsObject != undefined && itemsObject[0] != undefined && itemsObject[0].Name != undefined ){
            this.props.onItemSelected(itemsObject[0].Name,this.doesItemAlreadyExists(itemsObject[0].Name));
        }
    }

    doesItemAlreadyExists(itemText:string){
        const items = this.state.items;
        if(items){
            for(let i=0;i<items.length;i++){
                if(items[i].Name.toLowerCase() == itemText.toLowerCase()){
                    return true;
                }
            }
        }
        return false;
    }
}




export default ItemsAutocomplete;