/**
 * Created by alexd on 11/9/2020.
 */
import React,{useState} from 'react';
import {Button} from 'react-bootstrap'
import DatePicker from "react-datepicker";
import moment from "moment";


function CustomDatePicker({startDate,handleDateChange}){

    let dateMoment = moment(startDate, 'YYYY-MM-DD');
    if(!dateMoment.isValid()){
        let currentDate = moment();
        let futureMonth = moment(currentDate).add(1, 'M');
        let futureMonthEnd = moment(futureMonth).endOf('month');
        if(currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
            futureMonth = futureMonth.add(1, 'd');
        }
        dateMoment = futureMonth;
        handleDateChange(dateMoment.format("YYYY-MM-DD"))
    }


    const ExampleCustomInput = ({ value, onClick }) => (
        <Button variant="secondary" type="button" onClick={onClick}>{value}</Button>
    );
    return (
        <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={dateMoment.toDate()}
            onChange={date => {
                handleDateChange(moment(date).format("YYYY-MM-DD"));
            }}
            customInput={<ExampleCustomInput
                value={dateMoment.toDate()}
                onClick={(val)=>this.onClick(val)}
            />}
        />
    );
}

export default CustomDatePicker;
