import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';
import { Trans } from "react-i18next";
import i18n from "i18next";
import TextField from '@material-ui/core/TextField';
import ItemsAutocomplete from "../_other/ItemsAutocomplete";

import CustomDatePicker from "../_other/CustomDatePicker";

let sha1 = require('sha1');

interface MyProps {
    selectedUser:any,
    onUserAdded():any,
    onUserEdited():any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedUser:any,
    companies,
}

class ModalAddNewUser extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:[],

            selectedUser: {},
            companies: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);

        this.onCompanySelected = this.onCompanySelected.bind(this);
        this.fetchCompanies = this.fetchCompanies.bind(this);
    }

    componentDidMount(){
        this.fetchCompanies();
    }


    handleDateChange(newDate){
        let formFields = this.state.formFields;
        formFields['ValidUntil'] = newDate;
        this.setState({ formFields: formFields });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields,selectedUser } = this.state;
        const Name = formFields.Name;
        const Password = sha1(formFields.Password);
        const Email = formFields.Email;
        const Username = formFields.Username;
        const TotalEntries = formFields.TotalEntries;
        const UsedEntries = formFields.UsedEntries;
        const ValidUntil = formFields.ValidUntil;
        const Company = formFields.Company;


        // stop here if form is invalid
        if (!(Name)  || !(Email)|| !(Username)) {
            toast.error(i18n.t("msg_missing_fields"));
            return;
        }

        if(typeof(this.state.selectedUser) == "undefined" && !(formFields.Password)){
            toast.error(i18n.t("msg_missing_fields"));
            return;
        }


        let user = {Name: Name,Username: Username, Email: Email};

        if(ValidUntil){
            user['ValidUntil'] = ValidUntil;
        }

        if(formFields.Password){
            user['Password'] = Password;
        }

        if(TotalEntries){
            user['TotalEntries'] = TotalEntries;
        }

        if(UsedEntries){
            user['UsedEntries'] = UsedEntries;
        }

        if(selectedUser){
            user['IdUser'] = selectedUser.IdUser;
        }

        if(formFields.Company){
            user['Company'] = Company;
        }

        serverService.insertUser(user)
            .then(
                result => {
                    if(result.status == 1){
                        console.log("selectedUser: ");
                        console.log(selectedUser);
                        if(selectedUser != null && selectedUser.IdUser !== undefined){
                            toast.success(i18n.t("msg_user_edited"));
                            this.props.onUserEdited();
                        } else {
                            toast.success(i18n.t("msg_user_added"));

                            let formFields = this.state.formFields;
                            formFields.Name = "";
                            formFields.Password = "";
                            formFields.Email = "";
                            formFields.Username = "";
                            formFields.TotalEntries = "";
                            formFields.UsedEntries = "";
                            formFields.Company = "";

                            this.setState({formFields: formFields});

                            this.props.onUserAdded();
                        }

                    } else {
                        toast.error(i18n.t("msg_user_add_failed"));
                    }
                    this.setState({submitted: false});
                }, error => {
                    toast.warn(i18n.t("msg_user_add_failed_server"));
                }
            );

    }

    componentDidUpdate(prevProps, prevState){
        if((this.props.selectedUser !== prevProps.selectedUser)){

            let formFields = this.state.formFields;
            formFields.Name = "";
            formFields.Password = "";
            formFields.Email = "";
            formFields.Username = "";
            formFields.TotalEntries = "";
            formFields.UsedEntries = "";
            formFields.Company = "";
            formFields.ValidUntil = "";


            if(this.props.selectedUser && typeof(this.props.selectedUser.IdUser) !== "undefined"){
                formFields.Name = this.props.selectedUser.Name;
                formFields.Password = "";
                formFields.Email = this.props.selectedUser.Email;
                formFields.Username = this.props.selectedUser.Username;
                formFields.TotalEntries = this.props.selectedUser.TotalEntries;
                formFields.UsedEntries = this.props.selectedUser.UsedEntries;
                formFields.Company =  this.props.selectedUser.Company;
                formFields.ValidUntil = this.props.selectedUser.ValidUntil;
            }

            this.setState({selectedUser: this.props.selectedUser, formFields:formFields, submitted: false});
        }
    }


    render() {

        // console.log(this.state.selectedUser);

        const {submitted, formFields} = this.state;


        let titleStr = i18n.t("add_user");
        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            titleStr = i18n.t("edit_user");
        }

        let confirmButtonStr = i18n.t("add_user");
        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            confirmButtonStr = i18n.t("save");
        }

        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <br/>

                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.name ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="Name"
                                value={formFields.Name}
                                label={i18n.t("name")}
                                placeholder={i18n.t("name")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="text"
                            />

                            {submitted && !formFields.Name &&
                            <div className="help-block">
                                <Trans>
                                    name_is_mandatory
                                </Trans>
                            </div>
                            }
                        </div>


                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.Username ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="Username"
                                value={formFields.Username}
                                label={i18n.t("username")}
                                placeholder={i18n.t("username")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="text"
                            />

                            {submitted && !formFields.Email &&
                            <div className="help-block">
                                <Trans>
                                    email_is_mandatory
                                </Trans>
                            </div>
                            }
                        </div>

                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.Email ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="Email"
                                value={formFields.Email}
                                label={i18n.t("email")}
                                placeholder={i18n.t("email")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="text"
                            />

                            {submitted && !formFields.Email &&
                            <div className="help-block">
                                <Trans>
                                    email_is_mandatory
                                </Trans>
                            </div>
                            }
                        </div>


                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.Password ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="Password"
                                value={formFields.Password}
                                label={i18n.t("password")}
                                placeholder={i18n.t("password")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="password"
                            />

                            {submitted && !formFields.Password && typeof(this.state.selectedUser) == "undefined" &&
                            <div className="help-block">
                                <Trans>
                                    password_is_mandatory
                                </Trans>
                            </div>
                            }
                        </div>


                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.TotalEntries ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="TotalEntries"
                                value={formFields.TotalEntries}
                                label={i18n.t("total_entries")}
                                placeholder={i18n.t("total_entries")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="text"
                            />
                        </div>

                        <div className={'form-group mb-4 text-center' + (submitted && !formFields.UsedEntries ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="UsedEntries"
                                value={formFields.UsedEntries}
                                label={i18n.t("used_entries")}
                                placeholder={i18n.t("used_entries")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="text"
                            />
                        </div>


                        <div className={'form-group mb-4'} style={{marginLeft:'45px'}}>
                            <Trans>valid_until</Trans>
                            <br/>
                            <CustomDatePicker startDate={formFields.ValidUntil} handleDateChange={this.handleDateChange} />
                        </div>


                        <div className={'form-group mb-4 form-field'} style={{marginLeft:'auto',marginRight:'auto'}}>
                                <Form.Label>Companie</Form.Label>
                                <ItemsAutocomplete
                                    selectedItem={this.state.formFields.Company}
                                    items={this.state.companies}
                                    onItemSelected={this.onCompanySelected}
                                />
                        </div>




                        <br/>
                        <hr/>

                        <Button variant="primary" type="submit" style={{float:'right'}}>
                            {confirmButtonStr}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }


    fetchCompanies(){
        serverService.getCompanies()
            .then(
                result => {
                    if(result.status == 1){
                        this.setState({
                            companies:result.data,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get companies");
                }
            );
    }


    onCompanySelected(Company){
        let formFields = this.state.formFields;
        formFields['Company'] = Company;
        this.setState({ formFields: formFields });
    }

}

export default ModalAddNewUser;