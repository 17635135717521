import React from 'react';
import {withRouter} from 'react-router-dom'
import {Navbar,Nav} from 'react-bootstrap'
import { Link,NavLink } from 'react-router-dom';
import {Trans} from "react-i18next";


interface MyProps {
    user:any;
}

class SideNavbar extends React.Component<MyProps> {

    constructor(props) {
        super(props);
    }

    render() {
        var labelStyle = {
            color: '#CCCCCC',
        };

        const { user } = this.props;

        if(user && user.AuthToken &&  user.Type == 2){
            return "";
        }
        return (
            <div id="sidebar-menu">
                <Nav>
                    {(user && user.AuthToken && (user.Type == 2 || user.Type == 1)) &&
                    <Navbar.Text>
                        <Nav.Link>
                            <NavLink to="/home" activeClassName="selected btn-block" className="normal">
                                <Trans>
                                    home
                                </Trans>
                            </NavLink>
                        </Nav.Link>
                        {/*<Nav.Link>*/}
                        {/*<NavLink to="/settings" activeClassName="selected btn-block" className="normal">*/}
                        {/*<Trans>*/}
                        {/*settings*/}
                        {/*</Trans>*/}
                        {/*</NavLink>*/}
                        {/*</Nav.Link>*/}
                    </Navbar.Text>
                    }

                    {(user && user.AuthToken && (user.Type == 1)) &&
                    <>
                    <br/><br/><br/>
                    <i>
                        <Trans>
                            admin_pages
                        </Trans>
                    </i>
                    <br/>
                    <Nav.Link>
                        <NavLink to="/users" activeClassName="selected btn-block" className="normal">
                            <Trans>
                                users
                            </Trans>
                        </NavLink>
                    </Nav.Link>

                    <Nav.Link>
                        <NavLink to="/companies" activeClassName="selected btn-block" className="normal">
                            <Trans>
                                companies
                            </Trans>
                        </NavLink>
                    </Nav.Link>
                    </>
                    }

                </Nav>
            </div>
        );
    }

}


export default withRouter(SideNavbar);
