import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row, Col, ButtonToolbar, Button } from "react-bootstrap";
import { config } from '../../_helpers/C'


import { Trans } from "react-i18next";
import i18n from "i18next";
import CompaniesTable from "./CompaniesTable";

interface MyProps {
}

interface MyState {
    loading: boolean,
    modalAddNewShow: boolean,
    modalConfirmDeleteShow: boolean,
    selectedCompany: any,
}

class CompaniesPage extends React.Component<MyProps, MyState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            modalAddNewShow: false,
            modalConfirmDeleteShow: false,
            selectedCompany: {}
        };
    }

    componentDidMount() {
    }

    render() {
        let userStr = localStorage.getItem('user');
        let loggedInUser;
        if (userStr) {
            loggedInUser = JSON.parse(userStr);

            return (
                <div id="homeContainer">

                    <CompaniesTable />

                    <br />

                </div>
            );
        }
    }


}

export { CompaniesPage };