import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Route, withRouter, Switch, Redirect, RouteProps } from 'react-router-dom';
import { PrivateRoute } from '../_components/PrivateRoute';
import { LoginPage } from '../ui/LoginPage/LoginPage';
import { HomePage } from '../ui/HomePage/HomePage';
import SideNavbar from '../ui/_other/SideNavbar';
import LandingPage from "../ui/LandingPage/LandingPage";
import SettingsPage from '../ui/SettingsPage/SettingsPage';
import { NavDropdown } from "react-bootstrap";
import './App.scss'
import i18n from "i18next";
import { Trans } from 'react-i18next';

import {ForgotPasswordPage} from "../ui/ForgotPassword/ForgotPasswordPage";
import {ChangePasswordPage} from "../ui/ForgotPassword/ChangePasswordPage";
import {DebugPage} from "../ui/DebugPage/DebugPage";
import {UsersPage} from "../ui/UsersPage/UsersPage";
import {CompaniesPage} from "../ui/CompaniesPage/CompaniesPage";

const qs = require('query-string');


type MyState = {
    user: string,
};

class App extends React.Component<RouteProps, MyState> {

    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
        };

        this.onLogout = this.onLogout.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ user: JSON.parse(localStorage.getItem("user")) });
        }
    }

    componentDidMount() {

    }

    render() {
        let { user } = this.state;

        let topHeaderLabel = "";
        if(user){
            topHeaderLabel = i18n.t("connected_as")  + " " + user['Username'];
        }

        return (
            <>
                <div className="page-wrapper">
                    <div className="container">
                        <nav className="main-nav">
                            <div className="row align-items-center">
                                <div className="col">
                                    <a href="/home">
                                        <div className="brand">
                                            <img src="/assets/images/logo.png" alt="Logo" />
                                            SenecaApp
                                        </div>
                                    </a>
                                </div>
                                <div className="col text-right">
                                    {user &&
                                        <NavDropdown alignRight title={topHeaderLabel} id="basic-nav-dropdown">
                                            <NavDropdown.Item  onClick={this.onLogout}>
                                                <Trans>
                                                    logout
                                                </Trans>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    }
                                </div>
                            </div>
                        </nav>

                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar={true}
                        />

                        {user &&
                            <SideNavbar user={this.state.user} />
                        }
 
                        <div>
                            <Switch>
                                {/*<Route path="/hello" component={LandingPage} />*/}
                                <Route path="/login" component={LoginPage} />

                                <Route path="/forgotpassword" component={ForgotPasswordPage} />
                                <Route path="/resetpassword" component={ChangePasswordPage} />

                                <PrivateRoute exact path="/home" component={HomePage} />
                                <PrivateRoute exact path="/settings" component={SettingsPage} />

                                <PrivateRoute exact path="/stresstest" component={DebugPage} />
                                <PrivateRoute path="/users" component={UsersPage} />
                                <PrivateRoute path="/companies" component={CompaniesPage} />

                                <Redirect from='*' to='/home' />
                            </Switch>
                        </div>
                    </div>
                </div>

                <footer>
                    <div className="row">
                        <div className="col-md">
                            <Trans>
                                 all_rights_reserved
                             </Trans>
                        </div>
                    </div>
                </footer>
            </>
        );
    }


    onLogout(){
        localStorage.removeItem('user');
        window.location.href = "login";
        // this.props.history.push("/login");
    }

}

export default withRouter(props => <App {...props} />);