/**
 * Created by alexd on 8/5/2020.
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './res/locales/translation-en.json';
import translationRO from './res/locales/translation-ro.json';


// the translations
// (tip move them in a JSON file and import them)
const resources = {
    ro: {
        translation: translationRO
    },
    en: {
        translation: translationEN
    },
};

i18n
    //.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'ro',
        resources,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;