import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { config } from '../../_helpers/C'


import { Trans } from "react-i18next";
import i18n from "i18next";
import EventsTable from "./EventsTable";

import moment from "moment";

interface MyProps {
}

interface MyState {
    loading: boolean,
    user: any,
}

class HomePage extends React.Component<MyProps, MyState> {
    private loggedInUser;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            user: {},
        };
    }

    componentDidMount() {
        let userStr = localStorage.getItem('user');
        if (userStr) {
            this.loggedInUser = JSON.parse(userStr);
        }
        serverService.getUserById(this.loggedInUser.IdUser)
            .then(
                userRes => {
                    if (userRes && userRes.data != undefined) {
                        this.setState({ user: userRes.data, loading: false })
                    } else {
                        this.setState({ loading: false })
                    }
                },
                error => {
                    toast.warn("Did not get user");
                    this.setState({ loading: false });
                }
            )

    }

    render() {
        if (this.loggedInUser) {
            let user = this.state.user;
            console.log(user);

            let Company = user.Company;
            let AvailableEntries = user.TotalEntries - user.UsedEntries;
            let message = "";
            let valability = "";
            let companyMessage = "";
            if(Company) {
                companyMessage = " (in cadrul companiei " + Company + ")";
            }
            if(AvailableEntries == 0){
                message = this.loggedInUser['Name'] + ", ai consumat toate intrarile disponibile "
                    + "(" + user.UsedEntries + "/" + user.TotalEntries + ") " + companyMessage;
            } else {
                message = this.loggedInUser['Name'] + ", ti-au mai ramas " + AvailableEntries + " intrari " + companyMessage;
                valability = i18n.t("valid_until") + ": " + moment(user['ValidUntil']).format("DD/MM/YYYY");
            }



            let qrCodeImg = config.API_BASE_URL + "/img/user_" + user.EventToken + ".png";

            return (
                <div id="homeContainer">

                    <div style={{textAlign:'center', display: 'block'}}>
                        <h2>{message}</h2>
                        <h3>{valability}</h3>
                        <a href={qrCodeImg} target="_blank" download="qr.jpg">
                            <img  src={qrCodeImg} />
                        </a>
                    </div>


                    <br />

                    <EventsTable />

                    <br />

                </div>
            );
        }

        return "";
    }

}

export { HomePage };