import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Trans } from 'react-i18next';

import i18n from "i18next";

import TextField from '@material-ui/core/TextField';

let sha1 = require('sha1');

interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields: any,
}

class ForgotPasswordPage extends React.Component<MyProps & RouteComponentProps, MyState> {

    private tempLoggedInUser;

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });

        const { formFields } = this.state;
        const Email = formFields.Email;

        // stop here if form is invalid
        if (!(Email)) {
            toast.warn(i18n.t("msg_missing_fields"));
            return;
        }

        this.setState({ loading: true });

        let language = "en";
        if(i18n.language){
            language = i18n.language;
        }

        serverService.forgotPassword(Email,language)
            .then(
                response => {
                    if(response.status === 0){
                        toast.warn(i18n.t("user_does_not_exist"));
                    } else {
                        toast.success(i18n.t("msg_reset_link_was_sent"));
                    }

                    formFields.Email = "";
                    this.setState({ loading: false,submitted: false, formFields:formFields, });
                },
                error => {
                    toast.error(i18n.t("something_went_wrong"));
                    this.setState({ loading: false });
                }
            );
    }

    render() {
        const { formFields, submitted, loading, error } = this.state;
        const Email = formFields.Email;

        return (
            <div>
                <div className="col-md-6 col-md-offset-3 text-center mx-auto" id="loginContainer">
                    <h2 className="text-signin">
                        <Trans>
                            forgot_password
                        </Trans>
                    </h2>
                    <br />
                    <form name="form" className={`text-center`} onSubmit={this.handleSubmit}>
                        <div className={'form-group mb-4' + (submitted && !Email ? ' has-error' : '')}>
                            <Trans>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="Email"
                                    value={Email}
                                    label={i18n.t("email")}
                                    placeholder={i18n.t("email")}
                                    onChange={this.handleChange}
                                    className="form-field"
                                />
                            </Trans>

                            {submitted && !Email &&
                                <div className="help-block">
                                    <Trans>
                                        email_is_mandatory
                                </Trans>
                                </div>
                            }
                        </div>
                        <br />
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <button className="landing-button has-bg mt-2 btn-login" disabled={loading}>
                                        <Trans>
                                            reset_my_password
                                        </Trans>
                                    </button>
                                    {
                                        loading &&
                                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </div>
                            </Col>
                        </Row>

                        {
                            error &&
                            <div className={'alert alert-danger'}>{error}</div>
                        }
                    </form>
                </div>

            </div>

        );
    }


}


export { ForgotPasswordPage };