import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Trans } from 'react-i18next';

import i18n from "i18next";

import TextField from '@material-ui/core/TextField';

let sha1 = require('sha1');

const qs = require('query-string');


interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields: any,
}

class ChangePasswordPage extends React.Component<MyProps & RouteComponentProps, MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });

        const { formFields } = this.state;
        const password1 = formFields.password1;
        const password2 = formFields.password2;

        // stop here if form is invalid
        if (!(password1) || !(password2)) {
            toast.warn(i18n.t("msg_missing_fields"));
            return;
        }

        if(password1 != password2){
            toast.warn(i18n.t("passwords_must_match"));
            return;
        }

        let h = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).h;
        let IdUser = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdUser;

        this.setState({ loading: true });

         serverService.resetPassword(IdUser, sha1(password1), h)
             .then(
                 response => {
                     if(response.status == 1){
                         toast.success(i18n.t("password_was_resetted_successfully"));
                         this.setState({ loading: false,submitted:false, formFields: {password1: "", password2: ""} });
                    } else {
                         toast.success(i18n.t("unauthorized_access"));
                         this.setState({ loading: false });
                     }
                 }, error => {
                     toast.error(i18n.t("something_went_wrong"));
                     this.setState({ loading: false })
                 }
             );
    }

    render() {
        const { formFields, submitted, loading, error } = this.state;
        const password1 = formFields.password1;
        const password2 = formFields.password2;

        return (
            <div>
                <div className="col-md-6 col-md-offset-3 text-center mx-auto" id="loginContainer">
                    <h2 className="text-signin">
                        <Trans>
                            change_password
                        </Trans>
                    </h2>
                    <br />
                    <form name="form" className={`text-center`} onSubmit={this.handleSubmit}>
                        <div className={'form-group mb-0' + (submitted && !password1 ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="password1"
                                value={password1}
                                label={i18n.t("password")}
                                placeholder={i18n.t("password")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="password"
                            />

                            {submitted && !password1 &&
                            <div className="help-block">
                                <Trans>
                                    password_is_mandatory
                                </Trans>
                            </div>
                            }
                        </div>

                        <br />

                        <div className={'form-group mb-0' + (submitted && !password2 ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="password2"
                                value={password2}
                                label={i18n.t("retype_password")}
                                placeholder={i18n.t("retype_password")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="password"
                            />

                            {submitted && !password2 &&
                            <div className="help-block">
                                <Trans>
                                    password_is_mandatory
                                </Trans>
                            </div>
                            }
                        </div>

                        <br />
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <button className="landing-button has-bg mt-2 btn-login" disabled={loading}>
                                        <Trans>
                                            save
                                        </Trans>
                                    </button>
                                    {
                                        loading &&
                                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </div>
                            </Col>
                        </Row>

                        {
                            error &&
                            <div className={'alert alert-danger'}>{error}</div>
                        }
                    </form>
                </div>

            </div>

        );
    }



}


export { ChangePasswordPage };